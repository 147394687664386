let LegalLinksData = [{
    "title": "Disclaimer",
    "route": "disclaimer"
}, {
    "title": "Copyright Notice",
    "route": "copyright"
}, {
    "title": "Terms & Conditions",
    "route": "terms"
}, {
//     "title": "Privacy",
//     "route": "privacy"
// }, {
//     "title": "Security",
//     "route": "security"
// }, {
//     "title": "Cookie",
//     "route": "cookie"
}]

export default LegalLinksData