let HeaderLinksData = [{
    "title": "Our Technologies",
    "route": "technology"
// }, {
//     "title": "News",
//     "route": "updates"
// }, {
//     "title": "Publish",
//     "route": "publish"
// }, {
//     "title": "Reports",
//     "route": "report"
// }, {
//     "title": "Shop",
//     "route": "shop"
}, {
    "title": "Contact",
    "route": "contact"
}]

export default HeaderLinksData